html, body {
  font-family: Palatino, Palatino Linotype, Palatino LT STD, Book Antiqua, Georgia, serif;
}

.masthead {
  height: 100vh;
  min-height: 500px;
  overflow: hidden;
  position: relative;
}

.masthead-pic {
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  z-index: -1;
  background-image: url(/static/pics/masthead.jpg);
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  transform: scale(1.1);
  filter: blur(4px);
}

.masthead-motto {
  font-family: Courier New, Courier, Lucida Sans Typewriter, Lucida Typewriter, monospace;
  font-style: italic;
}

.sign {
  font-family: 'Vibur', cursive;
  min-height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  &_word {
    font-size: 5.6rem;
    text-align: center;
    line-height: 1;
    color: #c6e2ff;
    animation: neon .08s ease-in-out infinite alternate;
  }
}

@keyframes neon {
  from {
    text-shadow: 0 0 6px rgba(202, 228, 225, 0.92),
    0 0 30px rgba(202, 228, 225, 0.34),
    0 0 12px rgba(30, 132, 242, 0.52),
    0 0 21px rgba(30, 132, 242, 0.92),
    0 0 34px rgba(30, 132, 242, 0.78),
    0 0 54px rgba(30, 132, 242, 0.92);
  }
  to {
    text-shadow: 0 0 6px rgba(202, 228, 225, 0.98),
    0 0 30px rgba(202, 228, 225, 0.42),
    0 0 12px rgba(30, 132, 242, 0.58),
    0 0 22px rgba(30, 132, 242, 0.84),
    0 0 38px rgba(30, 132, 242, 0.88),
    0 0 60px rgba(30, 132, 242, 1);
  }
}
